import { cloneDeep, sortBy } from "lodash-es";
import { isStep } from "@/flow/utils/isStep";

export type Step = {
    actions: any,
    active: boolean,
    connection: any
    connectorSpecId: string,
    contentPath: string,
    createOrgId: string,
    createdAt: string
    createdBy: string
    deleted: boolean
    filter: [],
    flow: any,
    flowId: string,
    flowVersion: number
    id: string,
    ix: number,
    iy: number,
    modifiedBy: string
    name: string
    output: string,
    translations: [],
    updatedAt: string,
}

export type Route = {
    actions: any
    active: true
    createOrgId: string,
    createdAt: string,
    createdBy: string,
    deleted: boolean,
    flow: any,
    flowId: string
    flowVersion: string
    id: string
    ix: number,
    iy: number,
    name: string
    routes: Condition[]
    translations: [],
    type: "ROUTER"
    updatedAt: string
}

export type Flow = {
    active: boolean
    activities: [],
    createOrgId: string,
    createdAt: string,
    deleted: boolean,
    flowStatusType: string
    id: string
    latest: boolean
    modifiedBy: string
    name: string
    scheduleType: string,
    steps: Array<Step | Route>
};

export type Condition = {
    actions: any
    active: boolean,
    createOrgId: string,
    createdAt: string,
    createdBy: string,
    deleted: boolean,
    filter: [],
    id: string,
    ix: number,
    iy: number,
    name: string,
    parentId: string,
    steps: Array<Step | Route>,
    translations: []
    updatedAt: string
}

export function sortedByIxAndIy(flow: Flow): Flow {
  const currentFlow:Flow = cloneDeep(flow);
  function sorted(data: Flow | Condition): (Step | Route)[] {
    data.steps = sortBy(data.steps, [function (x: Step | Route) { return x.ix; }]);
    data.steps.forEach((item: Route | Step) => {
      if ("type" in item && item.type === "ROUTER") {
        item.routes = sortBy(item.routes, [function (y: Step | Route) { return y.iy; }]);
        item.routes.forEach((condition: Condition) => {
          if (condition) {
            sorted(condition);
          }
        });
      }
    });

    return data.steps;
  }

  currentFlow.steps = sorted(currentFlow);

  return currentFlow;
}

export function findSubFlowByStepUniqueId(flow: Flow, uniqueId: string): Array<Step & {uniqueId: string} | Route & {uniqueId: string}> {
  function find(data: Flow | Condition): Array<Step & {uniqueId: string} | Route & {uniqueId: string}> | undefined {
    let foundSteps: Array<Step & {uniqueId: string} | Route & {uniqueId: string}> | undefined;

    data.steps.forEach((stepOrRoute: Route & {uniqueId: string} | Step & {uniqueId: string}) => {
      if (stepOrRoute.uniqueId === uniqueId && isStep(stepOrRoute)) {
        foundSteps = data.steps as Array<Step & {uniqueId: string} | Route & {uniqueId: string}>;
      }
      if ("type" in stepOrRoute && stepOrRoute.type === "ROUTER") {
        const route = stepOrRoute as Route;
        route.routes.forEach((condition: Condition) => {
          if (!foundSteps) {
            const innerFoundFlow: Array<Step & {uniqueId: string} | Route & {uniqueId: string}> = find(condition);
            if (innerFoundFlow) {
              foundSteps = innerFoundFlow;
            }
          }
        });
      }
    });

    return foundSteps;
  }
  const currentFlow:Flow = cloneDeep(flow);
  return find(currentFlow);
}

export function findStepOrRouteByUniqueId(flow:Flow, id:string) {
  return findSubFlowByStepUniqueId(flow, id).find((x) => x.uniqueId === id);
}
